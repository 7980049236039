import React from "react";
import "./PrivacyPolicy.css"; // External CSS file for styles
import Navbar3 from "../navbar3";
import Footer3 from "../footer3";

const PrivacyPolicy = () => {
  return (
    <>
    <Navbar3></Navbar3>
    <div className="Policy-container">
      <h1 className="Policy-h1">Privacy Policy</h1>
      <p className="Policy-p">Effective Date: 1 January 2025</p>

      <div className="highlight">
        <p className="Policy-p">
          Welcome to eAlam Group. Your privacy is important to us. This Privacy Policy outlines the types of information we collect, how we use it, and the steps we take to safeguard your data when you engage with our services.
        </p>
      </div>

      <h2 className="Policy-h2">1. Information We Collect</h2>
      <p className="Policy-p">
        When you register on our website, we collect and store your profile information, including but not limited to your name, email address, and contact details. This information is retained for future communication, marketing campaigns, and service improvements.
      </p>
      
      <h2 className="Policy-h2">2. How We Use Your Information</h2>
      <ul>
        <li className="Policy-p">To communicate with you regarding updates, promotions, and relevant information.</li>
        <li className="Policy-p">To enhance your user experience and improve our services.</li>
        <li className="Policy-p">To enable interactions across eAlam services, including our apps and other provided services.</li>
      </ul>

      <h2 className="Policy-h2">3. Information Disclosure</h2>
      <p className="Policy-p">
        We do not disclose your profile information to other users or third parties. If you experience any incident or suspect unauthorized disclosure, please report it immediately to <a style={{
          textDecoration: "none",
          color: "#0f6cbd",
          cursor:"pointer",
        }}
        onMouseEnter={(e) =>
          (e.target.style.color = "#479ef5")
        }
        onMouseLeave={(e) =>
          (e.target.style.color = "#1a73e8")
        } href="mailto:contact@ealamgroup.com">incident@ealamgroup.pk</a>.
      </p>

      <h2 className="Policy-h2">4. Third-Party Services</h2>
      <p className="Policy-p">
        Our platform may integrate with third-party applications such as Google, Salesforce, and others. When using these services within the eAlam network of applications and services, their respective privacy policies apply. We encourage you to review their policies before using third-party services.
      </p>

      <h2 className="Policy-h2">5. Data Security</h2>
      <p className="Policy-p">
        We implement appropriate security measures to protect your personal information from unauthorized access, alteration, or disclosure. We will always strive to use the best practices and do our utmost to ensure the security of your data.
      </p>

      <h2 className="Policy-h2">6. Your Choices and Rights</h2>
      <ul>
        <li className="Policy-p">You can update or modify your profile information at any time.</li>
        <li className="Policy-p">You can opt out of marketing communications by following the unsubscribe instructions in our emails.</li>
        <li className="Policy-p">If you wish to delete your account or request access to your personal data, please contact us.</li>
      </ul>

      <h2 className="Policy-h2">7. Changes to This Privacy Policy</h2>
      <p className="Policy-p">
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.
      </p>

      <h2 className="Policy-h2">8. Contact Us</h2>
      <p className="Policy-p">
        If you have any questions or concerns about this Privacy Policy, please contact us at:
      </p>
      <p className="Policy-p">
        <strong>eAlamGroup.pk</strong>
        <br />
        Email: <a style={{
          textDecoration: "none",
          color: "#0f6cbd",
          cursor:"pointer",
        }}
        onMouseEnter={(e) =>
          (e.target.style.color = "#479ef5")
        }
        onMouseLeave={(e) =>
          (e.target.style.color = "#1a73e8")
        }
         href="mailto:privacy@ealamgroup.com">privacy@ealamgroup.com</a>
      </p>

    </div>
    <Footer3></Footer3>
    </>

  );
};

export default PrivacyPolicy;
